<template>
    <main>
        <div class="row no-gutters">
            <div class="col-xl-2 card-icon-aside bg-gray-200">
                <div class="text-center" v-if="usuario.sexo === 'F'">
                  <img class="py-2" src="@/assets/img-genero/mujer.png" width="96">
                  <h6><button class="btn btn-outline-dark btn-sm" data-toggle="modal" data-target="#perfilUsuario" >Ver más&nbsp;<i class="fa fa-eye"></i></button></h6>
                </div>
                <div class="text-center" v-else-if="usuario.sexo === 'M'">
                  <img class="py-2" src="@/assets/img-genero/hombre.png" width="96">
                  <h6><button class="btn btn-outline-dark btn-sm" data-toggle="modal" data-target="#perfilUsuario">Ver más&nbsp;<i class="fa fa-eye"></i></button></h6>
                </div>
            </div>
            <div class="col-xl-4">
                <div class="card-body">
                    <h6 >NOMBRE Y APELLIDO: {{usuario.nombre_1}} {{usuario.nombre_2}} {{usuario.apellido_1}} {{usuario.apellido_2}}</h6>
                    <h6 >TIPO DE DOCUMENTO: <span v-if="usuario.tipo_documento">{{usuario.tipo_documento.codigo }}</span></h6>
                    <h6 >IDENTIFICACION: {{usuario.documento}}</h6>
                </div>
            </div>
            <div class="col-xl-4">
                <div class="card-body">
                    <h6 >FECHA DE NACIMIENTO: {{usuario.fecha_nacimiento}}</h6>
                    <h6 >EDAD : <span class="badge badge-secondary">{{usuario.years}}</span> </h6>
                    <h6 >EPS: ESS207</h6>
                </div>
            </div>
        </div>  
    </main>
</template>
<script>
export default {
    props:['usuario','finalizada']
}
</script>

