<template>
    <main>
        <div class="modal fade " id="perfilUsuario" tabindex="-1" role="dialog" aria-labelledby="BuscarSede" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title "><i class="fas fa-id-card fa-fw"></i> DATOS DEL USUARIO</h4>
                        <button type="button"
                                class="close"
                                data-dismiss="modal"
                                aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                      <div class="row">
                            <div class="col-xl-4">
                                <!-- Profile picture card-->
                                <div class=" card-waves mb-4 mb-xl-0 ">
                                    <div class="card-body text-center">
                                        <!-- Profile picture image-->
                                        <div  v-if="usuario.sexo === 'F'">
                                          <img class="img-account-profile rounded-circle mb-2" src="@/assets/img-genero/mujer.png" >
                                        </div>
                                        <div class="text-center" v-else-if="usuario.sexo === 'M'">
                                          <img class="img-account-profile rounded-circle mb-2" src="@/assets/img-genero/hombre.png" >                                          
                                        </div>              
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-8">
                              <div class="card-waves mb-4">
                                  <div class="card-header  bg-light"><i class="fas fa-id-card-alt fa-fw text-primary"></i> Información Básica</div>
                                  <div class="card-body">                                        
                                    <div class="mb-3">
                                      <h6  class="">
                                        NOMBRE COMPLETO: <span class="text-body">{{usuario.nombre_1}} {{usuario.nombre_2}} {{usuario.apellido_1}} {{usuario.apellido_2}}</span>
                                      </h6>                        
                                    </div>
                                    <div class="mb-3">
                                      <h6  class="">IDENTIFICACION:
                                        <span class="text-body" v-if="usuario.tipo_documento">
                                          {{usuario.tipo_documento.codigo}}, No. {{usuario.documento}}
                                        </span>
                                      </h6>
                                    </div>
                                    <div class="mb-3">
                                        <h6  class="">FECHA DE NACIMIENTO:
                                          <span class="text-body">{{usuario.fecha_nacimiento}}</span>
                                        </h6>
                                    </div>  
                                    <div class="mb-3">
                                        <h6  class="">EDAD:
                                          <span class="text-body">{{usuario.years}}</span>
                                        </h6>
                                    </div> 
                                    <div class="mb-3">
                                        <h6  class="">SEXO:
                                          <span class="text-body">{{usuario.sexo}}</span>
                                        </h6>
                                    </div> 
                                    <div class="mb-3">
                                        <h6  class="">ESTADO CIVIL:
                                          <span class="text-body" v-if="usuario.estado_civil">
                                            {{usuario.estado_civil.descripcion}}
                                          </span>
                                        </h6>
                                    </div>                                          
                                  </div>
                              </div>
                            </div>
                            <div class="col-xl-12">
                              <div class="card-waves mb-4 ">
                                  <div class="card-header  bg-light"><i class="fas fa-address-book fa-fw text-primary"></i> Datos de Contacto</div>
                                  <div class="card-body">                                        
                                    <div class="mb-3">
                                      <h6  class="">
                                        DIRECCION: <span class="text-body">{{usuario.direccion}}</span>
                                      </h6>                        
                                    </div>
                                    <div class="mb-3">
                                      <h6  class="">DEPARTAMENTO:
                                        <span class="text-body" v-if="usuario.municipio">
                                          {{usuario.municipio.departamento.nombre}}
                                        </span>
                                      </h6>
                                    </div>
                                    <div class="mb-3">
                                        <h6  class="">MUNICIPIO:
                                          <span class="text-body" v-if="usuario.municipio">
                                            {{usuario.municipio.nombre}}
                                          </span>
                                        </h6>
                                    </div>  
                                    <div class="mb-3">
                                        <h6  class="">TELEFONO:
                                          <span class="text-body">{{usuario.telefono}}</span>
                                        </h6>
                                    </div>                                         
                                  </div>
                              </div>
                            </div>
                        </div>
                        
                    </div>
                    <div class="modal-footer">
                      <button type="button" class="btn btn-light btn-sm" data-dismiss="modal">Cerrar<i class="fa fa-times-circle fa-fw"></i></button>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
export default {
     props:['usuario'],
}
</script>
