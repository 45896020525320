<template>
    <main>
    <modalInformacionUsuario :usuario="usuario"></modalInformacionUsuario>
    <page-header-compact><h2 class="text-white">Historial Apgar Familiar</h2></page-header-compact>
    <div class="container-xl px-4 mt-n15">
      <div class="card ">
        <cardInformacionUsuario :usuario="usuario"></cardInformacionUsuario>
      </div>
      <div class="card mt-2" v-for="va in apgar" :key="va.id">
          <div class="card-header bg-light">Fecha: {{va.fecha}}</div>
          <div class="card-body">
          <div class="table-responsive">
              <table class="table table-striped table-bordered">
                    <tr>
                      <th>Pregunta</th>
                      <th>Valor</th>
                    </tr>
                    <tr v-for="item in va.valoracion_detalles" :key="item.id">
                        <td>
                            {{item.pregunta.descripcion}} 
                        </td>
                        <td>{{item.valor}}</td>
                    </tr>
                    <tr>
                        <td colspan="2"><span class="text-primary">Puntaje Total: </span>{{va.puntaje_total}}</td>
                        
                    </tr>
              </table>
              
              </div>
          </div>
      </div>
    </div>
  </main>
</template>
<script>
import modalInformacionUsuario from "../../base/informacionUsuario/modalInformacionUsuario";
import cardInformacionUsuario from "../../base/informacionUsuario/cardInformacionUsuario";
import usuarioService from '../../../../services/usuarioService';
import valoracionApgarFamiliarService from '../../../../services/valoracionApgarFamiliarService';
import PageHeaderCompact from "../../../../components/layouts/content/page-header-compact";


export default {
    components:{cardInformacionUsuario,modalInformacionUsuario,PageHeaderCompact},
    data() {
        return {
            usuario: {},
            apgar: {},
        };
    },
    methods: {
        async cargarUsuario(id) {
            const response = await usuarioService.show(id);
            this.usuario = response.data;
        },
        async cargarApgar(id){
            const response = await valoracionApgarFamiliarService.showAll(id);
            this.apgar = response.data;
        },
    },
    async created() {
        await this.cargarUsuario(this.$route.params.id);
        this.cargarApgar(this.$route.params.id);
    },
   
}
</script>